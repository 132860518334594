<template>
    <div class="recent-notification-list-container">
        <div class="st-filters">
            <div class="d-flex align-items-center justify-content-start">
                <p class="st-filters-header">{{ $t('NOTIFICATIONS_IN_APP.TITLE') }}</p>
            </div>
        </div>
        <div v-if="notificationMessages.length">
            <perfect-scrollbar
                v-if="!isLoading"
                class="navi navi-hover scroll mx-2"
                :class="columnsHeights"
                ref="scrollbar"
            >
                <template v-for="(item, i) in notificationMessages">
                    <div class="navi-item navi-item-custom my-4"  v-bind:key="i" @click="goToPage(item)">
                        <div class="row">
                            <div class="col-md-auto ml-4 mt-4">
                                <i class="h3 far fa-file-alt"></i>
                            </div>
                            <div class="col mt-3 mr-2">
                            <div class="font-weight-normal">
                                    <p class="my-0 text-break" v-html="item.message.body"></p>
                                    <p class="my-0">
                                        {{ $t('NOTIFICATIONS_IN_APP.MESSAGE.INPUT_CODE') }}:
                                        {{item.recipient.recipient_identification_number}}
                                    </p>
                                    <small class=" d-block text-right mr-4 mb-2">{{formatDate(item.created_date)}}</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </perfect-scrollbar>
        </div>
        <div :class="columnsHeights" v-else>
            <div class="row align-items-center h-100">
                <div class="col text-center">
                    <p class="font-size-h6 mb-6">
                       {{ $t('DASHBOARD.RECENT_NOTIFICATIONS.MISSING') }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
export default {
    name: 'RecentNotifications',
    props: {
        isStaff: {
            type: Boolean,
            default: true
        },
        notifications: {
            type: Array,
        },
        columnsHeights: {
            type: String,
            default: ''
        }
    },
     data() {
        return {
            notificationMessages: [],
        };
    },
    computed: {
        ...mapGetters({
            loading:  'shared/loading',
            listPeriod: 'notifications/inApp/listPeriod'
        }),
        isLoading() {
            return this.loading['notifications/getUserMessages'];
        }
    },
    created() {
        this.fetchNotifications();
    },
    methods: {
         ...mapActions({
            getUserMessages: 'notifications/inApp/getUserMessages',
        }),
        formatDate(date) {
            moment.updateLocale('ro',{
                relativeTime : {
                    future: `${this.$t('NOTIFICATIONS_IN_APP.LOCALE.FUTURE')} %s`,
                    past:  `${this.$t('NOTIFICATIONS_IN_APP.LOCALE.PAST')} %s`,
                    s  : this.$t('NOTIFICATIONS_IN_APP.LOCALE.SECOND'),
                    ss : `%d ${this.$t('NOTIFICATIONS_IN_APP.LOCALE.SECONDS')}`,
                    m:  this.$t('NOTIFICATIONS_IN_APP.LOCALE.MINUTE'),
                    mm: `%d ${this.$t('NOTIFICATIONS_IN_APP.LOCALE.MINUTES')}`,
                    h:  this.$t('NOTIFICATIONS_IN_APP.LOCALE.HOUR'),
                    hh: `%d ${this.$t('NOTIFICATIONS_IN_APP.LOCALE.HOURS')}`,
                }
            });
            const dateNow = moment(this.dateNow);
            const messageDate = moment(date);
            if (dateNow.diff(messageDate, 'hours') < 24) {
                const timeAgo = dateNow.from(messageDate, true);
                return `${this.$t('NOTIFICATIONS_IN_APP.NOW')} ${timeAgo}`
            }
            return moment(date).format('YYYY/MM/DD LT');
        },
        goToPage(item) {
           if (item.message.message_source) {
                const PAGE_LINKS = {
                    application: 'applicationView',
                    complain: 'complainView',
                };
                const name = PAGE_LINKS[item.message.message_source];
                this.$router.push({
                    name,
                    params: {
                        id: item.recipient.recipient_identification_id,
                    },
                });
            }
        },
        fetchNotifications() {
            this.getUserMessages(this.listPeriod)
            .then((data)=> {
                this.notificationMessages = data.data;
                this.$emit('update:notifications', this.notificationMessages);
            })
        },
    }
}
</script>
<style scoped lang="scss" src="@/assets/sass/pages/notifications/in-app-notifications.scss"></style>
