import { render, staticRenderFns } from "./OrganisationApplicationTypes.vue?vue&type=template&id=2954d498&scoped=true&"
import script from "./OrganisationApplicationTypes.vue?vue&type=script&lang=js&"
export * from "./OrganisationApplicationTypes.vue?vue&type=script&lang=js&"
import style0 from "./OrganisationApplicationTypes.vue?vue&type=style&index=0&id=2954d498&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2954d498",
  null
  
)

export default component.exports