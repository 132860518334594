<template>
    <div class="row">
        <div class="col-md-3 col-12" v-for="item in organisations" :key="item.tenant_name">
            <st-page>
                <div class="row mb-4 px-4">
                    <div class="col-4" v-if="item.logo">
                        <div class="d-flex justify-content-center h-100 pt-4">
                            <img :src="logoSrc(item)" alt="" class="mwh"/>
                        </div>
                    </div>
                    <div class="col-8">
                        <div class="title d-flex h-100 align-items-center">{{item.tenant_name}}</div>
                    </div>
                </div>
                <div class="row px-4">
                    <div class="col-12">
                        <div class="description" v-if="item.external_link">
                            {{item.description}}
                        </div>
                        <div :class="linkClass(item)" v-if="item.external_link">
                            <a :href="`${item.external_link}`" target="_blank">{{$t('GENERAL.ACTIONS.GO_TO_LINK')}}</a>
                        </div>
                        <ul class="application-list" v-if="displayAppTypes(item)">
                            <li v-for="app in item.details" :key="app.application_type_id" >
                                <router-link :to="`/application/new/${app.application_type_id}`">
                                    {{app.application_type_name}}
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </div>
            </st-page>
        </div>
    </div>
    
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'OrganisationApplicationTypes',
    data() {
        return {
            applicationTypes: [],
            organisations: []
        }
    },
    computed: {
        ...mapGetters({
            logoToken: 'shared/logoToken',
            defaultAvatar: 'shared/defaultAvatar',
        })
    },
    methods: {
        ...mapActions({
            getOrganisationApplicationTypes: 'applications/list/fetchApplicationTypesByOrganisation',
            getOrganisationsLogo: 'shared/getOrganisationsLogo',
        }),
        logoSrc(elem) {
            if (elem.logo) {
                return `${window.VUE_APP_API_URL}/files/${elem.logo}?token=${this.logoToken}`;
            }

            return this.defaultAvatar;
        },
        displayAppTypes(item) {
            return item?.details?.length > 0;
        },
        linkClass(item) {
            if (item.external_link && !item.details) {
                return 'external-link pb-8';
            }

            return 'external-link';
        }
    },
    async created() { 
        this.applicationTypes = await this.getOrganisationApplicationTypes();
        this.organisations = await this.getOrganisationsLogo();

        this.organisations = this.organisations.map((elem) => {
            let appType = this.applicationTypes.find((el) => elem.tenant_name === el.tenant_name);
            if (appType) {
                return {
                    ...elem,
                    details: appType.details
                }
            } 

            return elem;
        });
    }
}

</script>

<style scoped>
    .title {
        padding: 2rem 2rem 1rem 0;
        font-size: 1.25rem;
        font-weight: 500;
    }

    .application-list {
        list-style: circle;
    }

    .application-list li {
        padding-bottom: 4px;
        font-size: 14px;
    }

    .application-list li:last-of-type {
        padding-bottom: 2rem;
    }

    .external-link, .description {
        padding-left: 0;
        padding-bottom: 1rem;
        font-size: 14px;
    }

    .mwh {
        max-width: 100px;
        max-height: 100px;
    }
</style>