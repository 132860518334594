<template>
    <st-page layout="section" pageCustomClass="citizen-dashboard">
        <template #toolbar>
            <b-img :src="applicationImage" alt="applicationImage" class="welcome-image"></b-img>
            <div class="d-flex align-items-end justify-content-end">
                <st-button
                    variant="dark"
                    customClass="application-refresh-button"
                    :callback="refresh"
                >
                    <i class="fas fa-sync"></i>
                    {{ $t("APPLICATION.LIST.REFRESH_BUTTON") }}
                </st-button>
            </div>
        </template>
        <div class="row">
            <div class="col-12 col-md-3">
                <st-page customClass="pb-6 mt-3" :showHeader="false">
                    <recent-notifications
                        ref="recent-notifications"
                        :isStaff="isStaff"
                        :notifications.sync="notifications"
                        :columnsHeights="columnsHeights"
                    />
                </st-page>
            </div>
            <div class="col-12 col-md-9">
                <st-page customClass="pb-8 mt-3" :showHeader="false">
                    <recent-applications-list-filter :isStaff="isStaff" ref="recent-applications-list-filter">
                        <recent-applications-list-table
                            :isStaff="isStaff"
                            :columnsHeights="columnsHeights"
                            v-if="appRows.length"
                        />
                        <div v-else :class="columnsHeights">
                            <div class="row align-items-center h-100" v-if="!isLoading">
                                <div class="col text-center">
                                    <p class="font-size-h6 mb-6">
                                        {{ $t("DASHBOARD.RECENT_APPLICATIONS.MISSING") }}
                                    </p>
                                    <st-button
                                            variant="secondary"
                                            :callback="createRequest"
                                            id="btn_create_request"
                                            customClass="text-dark application-add-button px-6 py-4 mt-4"
                                        >
                                            <i class="fas fa-plus text-dark application-btn-icon"/>
                                            <span class="application-create-btn-text">{{ $t('MENU.CREATE_REQUEST_BUTTON') }}</span>
                                    </st-button>
                                </div>
                            </div>
                        </div>
                    </recent-applications-list-filter>
                </st-page>
            </div>
        </div>
        <div class="row">
            <div class="col-12 mt-6">
                <organisation-application-types></organisation-application-types>
            </div>
        </div>
    </st-page>
</template>

<script>
import RecentApplicationsListFilter from '@/modules/dashboard/components/recent-applications/RecentApplicationsListFilter';
import RecentApplicationsListTable from '@/modules/dashboard/components/recent-applications/RecentApplicationsListTable';
import RecentNotifications from '@/modules/dashboard/components/recent-notifications/RecentNotifications';
import OrganisationApplicationTypes from '@/modules/dashboard/components/organisation-application-types/OrganisationApplicationTypes.vue';
import { mapGetters } from 'vuex';
const applicationsModule = () => import('@/modules/applications/applications-store');

export default {
    name: 'DasboardCitizen',
    components: {
        RecentApplicationsListFilter,
        RecentApplicationsListTable,
        RecentNotifications,
        OrganisationApplicationTypes
    },
    props: {
        isStaff: {
            type: Boolean,
            default: true
        },
    },
    data() {
        return {
            notifications: [],
            applicationImage: `${process.env.BASE_URL}media/citizen-banner-150.png`,
        }
    },
    computed: {
        ...mapGetters({
            appRows: 'applications/list/rows',
            loading: 'shared/loading',
        }),
        columnsHeights() {
            let cssClass = 'dasboard-custom-height';
            if (!this.notifications.length && !this.appRows.length) return cssClass = 'dasboard-empty-results'
            if (this.notifications.length <= 2 && this.appRows.length <= 2) return cssClass = 'dasboard-some-results'
            return cssClass
        },
        isLoading() {
            return this.loading['applications/get'];
        }
    },
    methods: {
        refresh () {
            this.$refs['recent-notifications'].fetchNotifications();
            this.$refs['recent-applications-list-filter'].refresh();
        },
        createRequest() {
            this.$router.push({ name: 'applicationNew'})
        },
    },
    async created() {
        if (!this.$store.hasModule('applications')) {
            const applicationsStoreModule = await applicationsModule();
            this.$store.registerModule('applications', applicationsStoreModule.default);
        }
    }
}
</script>
<style >
.dasboard-empty-results {
    height: 150px;
}
.dasboard-some-results {
    height: 220px;
}
.dasboard-custom-height {
    height: 320px;
}

.welcome-image-container {
    margin-bottom: 4px;
}

.welcome-image {
    margin-top: -20px;
    z-index: 100;
}

.citizen-dashboard.st-page .st-page-header .st-page-toolbar {
    justify-content: space-between;
    width: 100%;
    align-items: flex-end;
}

.citizen-dashboard.st-page .st-page-header {
    height: auto;
}
</style>
